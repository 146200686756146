/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: calc(100% - 46px);
  top: 10px;
}

@media (min-width: 769px) {
  .bm-burger-button {
    display: none;
  }
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #fff;
  border: 1px solid #191D52;
  border-radius: 6px;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #fff;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  /*background: #373a47;*/
  background: linear-gradient(180deg, #191D52 0%, #3841B8 100%);
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  text-align: center;
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}