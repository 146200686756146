.header {
    background: linear-gradient(180deg, #191D52 0%, #3841B8 100%);
    color: #fff;
    padding-top: 28px;
    padding-right: 28px;
    padding-left: 28px;
    justify-content: start;
}

/* Alinhamento padrão para mobile */
.fixed {
    text-align: right;
    display: none; /* Hide on mobile */
}

/* Alinhamento para desktop */
@media (min-width: 768px) {
    .fixed {
        text-align: center;
        max-width: 200px !important;
        display: block; /* Show on desktop */
    }
}

.whiteOutline {
    border-color: #fff !important;
    border-width: 2px !important;
}


@keyframes flash {

    0%,
    50%,
    100% {
        opacity: 1;
    }

    25%,
    75% {
        opacity: 0;
    }
}

.flash {
    animation: flash 0.5s linear infinite;
}

.dropdown-menu {
    --bs-color-scheme: light;
    --bs-background: blue;
    --bs-text: white;
}

.dropdown-menu .dropdown-item {
    /*color: var(--bs-text) !important;*/
    color: red !important;
}

.dropdown-menu .dropdown-item:hover, .dropdown-menu .dropdown-item:focus {
    background-color: var(--bs-background) !important;
    color: white !important;
}

.my_dropdown_item{
    color: white !important;
}

.my_dropdown_item:hover, .my_dropdown_item:focus{
    color: black !important;
}

/*.my_li{
    color: green !important;
}*/

:global(.dropdown-item.active) {
    color: black !important;
    background-color: white !important;
}

:global(.dropdown-item:active) {
    color: black !important;
    background-color: white !important;
}
