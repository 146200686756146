.contatoParte1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*height: 100vh;*/
}

.btn {
    background-color: #191D52 !important;
    color: #fff !important;
    width: 200px !important;
    max-width: 100%;
    height: 63px !important;
    text-align: center;
    line-height: 45px;
    margin-bottom: 20px !important;
    font-weight: 900;
}

.btn:hover {
    background-color: transparent !important;
    border-color: #191D52 !important;
    color: #191D52 !important;
}

@media screen and (max-width: 395px) {
    .btn {
        width: 90% !important;
        font-size: 12px;
    }
}

:global(#toast-container .toast) {
    opacity: 1 !important; /* 1 means fully opaque, 0 means fully transparent */
}