.homeNossasSolucoes {
    background: url("../../public/fundoHomeBaixo.png");
    background-size: 100% 100%;
    min-height: 100vh;
    color: #fff;
    padding-top: 28px;
    padding-right: 28px;
    padding-left: 28px;
}

@media screen and (max-width: 768px) {
    .homeNossasSolucoes {
        min-height: 60vh;
        padding-top: 4px;
        padding-right: 4px;
        padding-left: 4px;
    }
}

.roundedShadowDiv {
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    background-color: #8F9EBA;
    text-align: center;
    margin: 20px;
    max-width: 390px;
    min-width: 300px;
    max-height: 511px;
    padding-top: 50px;
    /*position: relative;*/
}

.roundedShadowDiv h1 {
    min-height: 60px;
    font-size: 22px;
    font-weight: bold;
    color: #102D49;
    margin: 3px;
    text-transform: uppercase;
    
}

.roundedShadowDiv p {
    min-height: 90px;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
}

.roundedShadowDiv img {
    height: 130px;
}

.divTitulo {
    background-color: #f3f3f3;
    color: #fff;
    font-size: 30px;
    font-weight: 700;
    padding: 10px;
    border-radius: 10px 10px 10px 10px;
    display: inline-block
}



@media screen and (max-width: 768px) {
    .roundedShadowDiv {
        min-width: 90%;
        height: auto;
    }

    .divTitulo {
        font-size: 22px;
        font-weight: 700;
        /*border-style: solid;
        border-width: 1px;
        border-color: brown;*/
    }
}