.sobreNosParte1 {
    background: url("../../../public/sobreNos/sobreNos_fundo_parte1.png");
    background-size: cover !important;
    background-position: center !important;
    min-height: 100vh;
    color: #fff;
    padding-top: 28px;
    padding-right: 28px;
    padding-left: 50px;
}

.sobreNosParte1 h1{
    font-size: 40px;
    margin: 3px;
    text-transform: uppercase;
    letter-spacing: 2.1px;
    margin-top: 40px;
    font-weight: 800;
    text-decoration: underline;
}

.sobreNosParte1 h2 {
    font-size: 40px;
    margin: 3px;
    letter-spacing: 2.1px;
    margin-top: 45px;
    font-weight: 400;
}

.sobreNosParte1 p {
    font-size: 20px;
    margin: 3px;
    letter-spacing: 2.1px;
    margin-top: 45px;
    font-weight: 400;
    max-width: 50%;
}

.sobreNosParte1 * span {
    font-weight: 700;
}

@media screen and (max-width: 1200px) {
    .sobreNosParte1 {
        min-height: 100vh;
        padding-top: 10px;
        padding-right: 10px;
        padding-left: 10px;
    }


    .sobreNosParte1 h1{
        font-size: 25px;
    }
    
    .sobreNosParte1 h2 {
        font-size: 25px;
    }
    
    .sobreNosParte1 p {
        font-size: 18px;
        max-width: 90%;
    }
}