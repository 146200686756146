.sobreNosParte4 {
    background: url("../../../public/sobreNos/sobreNos_fundo_parte4.png");
    background-size: 100% 100%;
    min-height: 70vh;
    color: #191D52;
}

.roundedShadowDiv {
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    background-color: #8F9EBA;
    text-align: center;
    margin: 20px;
    width: 350px;
    min-height: 450px;
    /*max-height: 511px;*/
    padding-top: 50px;
    /*position: relative;*/
}

.textoBrancoNossasSolucoes {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    /*position: absolute;
    top: 130px;
    left: 10px;*/
    padding-right: 30px;
    padding-left: 30px;
}