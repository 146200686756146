.pageStart {
    text-align: center;
    padding-top: 180px;
    letter-spacing: 2.1px;
    font-weight: 400;

    background-size: cover !important;
    background-position: center !important;
    min-height: calc(95vh - 35px);
    color: #fff;
    padding-top: 28px;
    padding-right: 28px;
    padding-left: 50px;
}

.pageStart h1 {
    font-size: 56px;
    text-transform: uppercase;
}

.pageStart h2 {
    font-size: 56px;
}

.pageStart p {
    font-size: 24px;
}

.pageStart * span {
    font-weight: 700;
}

.nossasSolucoes {
    background-size: cover !important;
    background-position: center;
    min-height: 70vh;
    color: #191D52;
}

.nossasSolucoes h1 {
    font-size: 56px;
    letter-spacing: 2.1px;
    margin-top: 0px;
    font-weight: 800;
    text-transform: uppercase;
}

.nossasSolucoes p {
    font-size: 18px;
    letter-spacing: 2.1px;
    margin-top: 0px;
    font-weight: 400;
    max-width: 80%;
}

.nossasSolucoes p span {
    font-weight: 700;

}

.gifDoRobo {
    max-width: 600px;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.imagem {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

:global(.text_align_right) {
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

:global(.text_align_center) {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

@media screen and (max-width: 1200px) {

    /*.nossasSolucoes {
        text-align: center !important;
        display: inline !important;
        flex-direction: row !important;
        align-items: stretch !important;
    }*/
    .nossasSolucoes h1 {
        font-size: 33px;
    }
}

@media screen and (max-width: 768px) {
    .pageStart h1 {
        font-size: 35px;
    }

    .pageStart h2 {
        font-size: 35px;
    }

    .pageStart p {
        font-size: 24px;
    }

    .nossasSolucoes {
        text-align: center !important;
        padding-top: 15px;
        min-height: 30vh;

        display: inline !important;
        flex-direction: row !important;
        align-items: stretch !important;
    }

    .nossasSolucoes h1 {
        text-align: center !important;
        font-size: 25px;
    }

    .nossasSolucoes p {
        text-align: center !important;
        max-width: 100%;
        font-size: 14px;
    }
}


.sobreNosFinal {
    background-size: 100% 100%, cover !important;
    background-repeat: no-repeat;
    background-position: center;
    color: #191D52;
}

.sobreNosFinal p {
    font-size: 18px;
    letter-spacing: 2.1px;
    margin-top: 0px;
    font-weight: 400;
    margin-right: 50px;
    margin-left: 50px;
    text-align: center;
}

.sobreNosFinal p span {
    font-weight: 700;

}


.sobreNosTexto {
    height: 100%;
    width: 70%;
}


@media (max-width: 768px) {
    .sobreNosTexto {
        width: 100%;
    }
}