.homeVideo {
    background: url("../../public/video.gif");
    background-size: cover !important;
    background-position: center !important;
    min-height: calc(100vh - 92px);
    color: #fff;
    padding-top: 28px;
    padding-right: 28px;
    padding-left: 28px;
    /*position: relative;*/
}

.homeVideo p {
    font-size: 50px;
    white-space: pre-wrap;
    /*position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);*/
    text-align: center;
}

@media screen and (max-width: 768px) {
    .homeVideo p {
        font-size: 20px;
    }
    .homeVideo {
        min-height: 70vh;
        padding-top: 10px;
        padding-right: 10px;
        padding-left: 10px;
    }
}