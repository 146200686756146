.rodape {
    background: linear-gradient(180deg, #191D52 0%, #3841B8 100%);
    /*height: 429px;*/
    color: #fff;
    padding-top: 40px;
}

@media screen and (max-width: 768px) {
    .rodape {
        padding-top: 0px;
    }
}

.rodapeContato {
    width: 312px;
    /*height: 129px;*/
}

.rodape a {
    color: #fff;
    text-decoration: none;
}

.rodape a:hover {
    text-decoration: underline;
}