.politicaDeprivacidade {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 10px;
}

.politicaDeprivacidade h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 20px;
    text-align: center;
}

.politicaDeprivacidade h2 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
    margin-top: 15px;
}

/*.politicaDeprivacidade br {*/
    /*display: block;*/
    /*content: "";*/
    /*margin-bottom: 10px;*/
/*}*/

.politicaDeprivacidade span {
    font-weight: bold;
    /*color: #ff0000;*/
}

.politicaDeprivacidade a {
    color: black;
    text-decoration: none;
}